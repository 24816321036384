//@ts-ignore
import { useTranslation } from "react-i18next";
import { MapForReport } from "./PlacesAutocomplete";
import ProgressBar from "./ProgressBar";

import TopGaugeCattle from "./TopGaugeCattle";
import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";

interface RiskDataProps {
  profitData: any;
  toggleModal: () => void;
}
const climate_indices = ["Drought", "Temperature humidity"];
const water_indices = ["Water stress", "Ground Water Availability"];
const profitability_indices = ["Profitability"];
const PdfPrevLivestock = ({ profitData, toggleModal }: RiskDataProps) => {
  const { t } = useTranslation("home");
  // Calculate the difference in months between startDate and endDate
  console.log(profitData, " pdf prev");
  let temp1 = profitData?.adaptations ?? {},
    data = {};
  Object.keys(temp1).forEach((key) => {
    if (Object.keys(data).includes(temp1[key]?.Pillar)) {
      data = {
        ...data,
        [temp1[key]?.Pillar]: [...data?.[temp1[key]?.Pillar], temp1[key]],
      };
    } else {
      Object.assign(data, { [temp1[key]?.Pillar]: [temp1[key]] });
    }
  });
  return (
    <>
      {/* Invoice */}
      <div id="printablediv" className="max-w-[85rem]  mx-auto my-4 sm:my-10">
        <div className="flex  justify-center items-center  border-gray-200 dark:border-gray-700  sm:my-4">
          <div className="mb-8">
            <img alt="Logo" className="w-52 mx-auto" src="/img/logo.png" />
          </div>
        </div>
        {/* Grid */}
        <div className="grid md:grid-cols-2 gap-3 pb-5 px-4 sm:px-6 lg:px-8  border-b border-gray-200 dark:border-gray-700">
          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-[108px] max-w-[200px] text-gray-500">
                  {t("Full Name")}:
                </dt>
                <dd className="text-gray-800 dark:text-gray-200">
                  <input
                    type="text"
                    defaultValue={profitData?.debtor_name}
                    id="input-label"
                    className="min-w-[100%] rounded-sm border-gray-200   dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    placeholder="Full Name"
                  />
                </dd>
              </dl>
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-[100px] max-w-[200px] text-gray-500">
                  Phone Number:
                </dt>
                <dd className="font-medium text-gray-800 dark:text-gray-200">
                  <Controller
                    // control={control}
                    name="debtor_contacts"
                    render={({ field: {} }) => (
                      <PhoneInput
                        className="w-full border-gray-200"
                        defaultCountry="ke"
                        inputClassName="w-full border-gray-200"
                        name="phone"
                        // onChange={handlePhoneChange}
                      />
                    )}
                  />
                </dd>
              </dl>
            </div>
          </div>
          {/* Col */}
          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                  Location(Town):
                </dt>
                <dd className="font-medium text-gray-800 dark:text-gray-200">
                  <input
                    type="text"
                    id="input-label"
                    defaultValue={profitData?.location_name}
                    className="min-w-[100%] rounded-sm border-gray-200   dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    placeholder="Location"
                  />
                  <address className="not-italic font-normal">
                    {`LatLng(${profitData?.latitude || "Latitude"}, ${
                      profitData?.longitude || "Longitude"
                    })`}
                  </address>
                </dd>
              </dl>
            </div>
          </div>
          {/* Col */}
        </div>
        {/* End Grid */}
        <div className="pt-8 px-4 sm:px-6 lg:px-8">
          {/* Grid */}

          {/* End Grid */}

          <div className="mt-5 mb-9">
            <h4 className="text-lg  font-semibold text-gray-800 dark:text-gray-200">
              Detailed Risk Report Analysis
            </h4>
          </div>

          {/* Table */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg  dark:border-gray-700">
            <div className="flex flex-col items-center ">
              <h3 className="mb-2 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                Map
              </h3>
              {/* <img
              className="w-full object-none h-full  rounded-xl"
              src={mapUrl}
              alt="Image Description"
            /> */}

              <div className="h-80 md:h-full w-full">
                <MapForReport
                  lat={profitData?.latitude}
                  lng={profitData?.longitude}
                />
              </div>
            </div>
            <div className="flex flex-col  items-center ">
              <h3 className="  font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                Composite Risk Score
              </h3>

              <ProgressBar
                //@ts-ignore
                isBig={true}
                progress={profitData?.composite_total_risk || 0}
                width={300}
              />
              <div className="w-full md:pl-10">
                <div className="mt-2 sm:mt-2">
                  <h4 className="text-xs font-semibold uppercase text-gray-800 dark:text-gray-200">
                    Summary
                  </h4>
                  <ul className="mt-3 flex flex-col bg-[#FAFAFA]">
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                      <div className="flex items-center justify-between w-full">
                        <span>Loan Period</span>
                        {/* <span>{monthsDifference} Months</span> */}
                      </div>
                    </li>
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                      <div className="flex items-center justify-between w-full">
                        <span>Crop</span>
                        <span>{profitData?.crop}</span>
                      </div>
                    </li>
                    <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 ">
                      <div className="flex items-center justify-between w-full">
                        <span>Biodiversity</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="green"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="lucide lucide-badge-check"
                        >
                          <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* End Table */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5 mb-10">
            <TopGaugeCattle
              pillar="CLIMATE"
              categories={climate_indices}
              firstScore={profitData?.climate_scores?.drought_risk}
              secondScore={
                profitData?.climate_scores?.temperature_humidity_index_risk
              }
              composite_climate_risk={
                profitData?.climate_scores?.composite_climate_risk
              }
              // tooltip={scoreTooltipData[0].tip}
            />
            <TopGaugeCattle
              pillar="WATER"
              categories={water_indices}
              firstScore={profitData?.water_scores?.water_stress_risk}
              secondScore={profitData?.water_scores?.ground_water_risk}
              composite_climate_risk={
                profitData?.water_scores?.composite_water_risk
              }
              // tooltip={scoreTooltipData[0].tip}
            />
            <TopGaugeCattle
              pillar="PROFITABILITY"
              categories={profitability_indices}
              firstScore={profitData?.profitability_scores?.profitability_risk}
              composite_climate_risk={
                profitData?.profitability_scores?.profitability_risk
              }
              // tooltip={scoreTooltipData[0].tip}
            />
          </div>
          <div className="mt-5">
            <h4 className="text-2xl mb-2 font-semibold text-gray-800 dark:text-gray-200">
              Climate Adaptation Suggestions To Improve Your Score
            </h4>

            {Object.keys(data).map((key) => {
              return (
                <>
                  <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200">
                    {key}
                  </h4>
                  {data[key].map(({ Suggestion }) => {
                    return (
                      <>
                        <p className="text-gray-500 mt-2">
                          {profitData?.adaptations[1] && <li>{Suggestion}</li>}
                        </p>
                      </>
                    );
                  })}
                </>
              );
            })}
            {/* <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200">
              {profitData?.adaptations["1"]?.Pillar}
            </h4>
            <p className="text-gray-500 mt-2">
              {profitData?.adaptations["1"] && (
                <li>{profitData?.adaptations["1"]?.Suggestion}</li>
              )}
            </p>
            <p className="text-gray-500 mt-2">
              {profitData?.adaptations[2] && (
                <li>{profitData?.adaptations[2]?.Suggestion}</li>
              )}
            </p>
            <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200 mt-2">
              {profitData?.adaptations[3]?.Pillar}
            </h4>
            <p className="text-gray-500 mt-2">
              {profitData?.adaptations[3] && (
                <li>{profitData?.adaptations[3]?.Suggestion}</li>
              )}
            </p>
            <p className="text-gray-500 mt-2">
              {profitData?.adaptations[4] && (
                <li>{profitData?.adaptations[4]?.Suggestion}</li>
              )}
            </p>
            <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200 mt-2">
              {profitData?.adaptations[5]?.Pillar}
            </h4>
            <p className="text-gray-500 mt-2">
              {profitData?.adaptations[5] && (
                <li>{profitData?.adaptations[5]?.Suggestion}</li>
              )}
            </p>
            <p className="text-gray-500 mt-2">
              <p className="text-gray-500 mt-2">
                {profitData?.adaptations[6] && (
                  <li>{profitData?.adaptations[6]?.Suggestion}</li>
                )}
              </p>
            </p> */}
          </div>
          <div className="mt-8">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              Thank you!
            </h4>
            <p className="text-gray-500">
              If you have any questions concerning this report, use the
              following contact information:
            </p>
            <div className="mt-2">
              <p className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                info@adapta.earth
              </p>
            </div>
          </div>
          <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>

          <div className="mt-5 px-4 sm:px-6 lg:px-8">
            <p>Add a comment(Optional)</p>
            <textarea
              className=" mt-2 py-3 px-4 block w-full border-gray-200 rounded-md border text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
              rows={3}
              placeholder="Enter a comment ..."
              defaultValue={profitData?.reviewer_comments}
            />
          </div>
        </div>

        <button
          type="button"
          onClick={toggleModal}
          className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
        >
          Close
        </button>
      </div>
      {/* End Invoice */}
    </>
  );
};

export default PdfPrevLivestock;
